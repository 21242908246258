const video = () => {
    const videos = document.querySelectorAll('.video');

    if (!videos.length) return;

    videos.forEach((video) => {
        const soundButton = video.querySelector('.video__overlay__button-sound');

        if (soundButton) {
            soundButton.addEventListener('click', (event) => {
                if (event.target === soundButton) {
                    video.classList.toggle('is--unmuted');

                    if (video.classList.contains('is--unmuted')) {
                        video.querySelector('video').muted = false;
                        soundButton.textContent = 'sound on'
                    } else {
                        video.querySelector('video').muted = true;
                        soundButton.textContent = 'sound off'
                    }
                }
            });            
        }

        video.addEventListener('click', (event) => {
            if (event.target != soundButton) {
                video.classList.toggle('is--playing');

                if (video.classList.contains('is--playing')) {
                    video.querySelector('video').play();
                } else {
                    video.querySelector('video').pause();
                }
            }
        });
    });
};

document.addEventListener('DOMContentLoaded', video());