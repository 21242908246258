const redirectAfterSubmit = () => {
    const forms = document.forms;

    for (const form of forms) {
        form.addEventListener('submit', async (event) => {
            event.preventDefault();

            const formData = new FormData(form);

            const response = await fetch('/', {
                method: 'POST',
                body: formData,
            });

            window.location.href = `${window.location.origin}/kontakt/danke`;
        });
    }
};

document.addEventListener('DOMContentLoaded', redirectAfterSubmit());
