import Swiper from 'swiper';
import { Navigation, Pagination, EffectFade } from 'swiper/modules';

(function () {
    const slider = document.querySelectorAll('.swiper');

    if (slider.length) {
        slider.forEach(slide => {
            const id = slide.id;
            const target = document.querySelector(`#${id}`)
            const settings = JSON.parse(slide.dataset.settings);

            new Swiper(target, {
                ...settings,
                spaceBetween: 16,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
                pagination: {
                    el: '.swiper-pagination',
                },
                modules: [Navigation, Pagination, EffectFade],
            });
        });
    }
}());