import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { TextPlugin } from "gsap/TextPlugin";

import { debounce, inViewport } from "./utils";

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin, TextPlugin);

const isBackend = document.querySelector('#application');

const videoAnimation = (gsap) => {
    const heroSection = document.querySelector('.home-hero');

    if (!heroSection) {
        return;
    }

    const gallerySection = document.querySelector('.home-hero__grid-gallery');
    const video = document.querySelector('.hero-video');
    const videoContainer = document.querySelector('.scale-container');
    const videoOriginalWidth = video.getBoundingClientRect().width;
    const videoOriginalHeight = video.getBoundingClientRect().height;

    // Get Row height
    const gridContainer = gallerySection;
    const style = window.getComputedStyle(gridContainer);
    const gridTemplateRows = style.getPropertyValue('grid-template-rows');
    const rowDefinitions = gridTemplateRows.split(' ');

    let mm = gsap.matchMedia()

    const tl = gsap.timeline({
        scrollTrigger: {
            trigger: heroSection,
            start: 'top top',
            snap: 0.5,
            end: () => innerHeight * 2,
            scrub: 1,
        }
    })

    // tl.to(videoContainer, {
    //     delay: 0.3,
    //     duration: 10,
    //     y: gridContainer.offsetHeight / rowDefinitions.length * 2 * -1,
    //     // y: 0,
    //     width: '100vw',
    //     height: '100lvh',
    //     ease: 'power1.inOut',
    //     backgroundColor: '#10002F',
    //     onComplete: () => {
    //         video.play();
    //     }
    // })

    mm.add("(min-width: 767px)", () => {
        tl.to(videoContainer, {
            delay: 0.3,
            duration: 10,
            y: gridContainer.offsetHeight / rowDefinitions.length * 2 * -1,
            width: '100vw',
            height: '100lvh',
            ease: 'power1.inOut',
            backgroundColor: '#10002F',
            onComplete: () => {
                video.play();
            }
        })
    });

    mm.add("(max-width: 767px)", () => {
        tl.to(videoContainer, {
            delay: 0.3,
            duration: 10,
            y: 0,
            width: '100vw',
            height: '100lvh',
            ease: 'power1.inOut',
            backgroundColor: '#10002F',
            onComplete: () => {
                video.play();
            }
        })
    });

    tl.to(videoContainer, {
        delay: 3,
        ease: 'power1.inOut',
        duration: 10,
        position: 'relative',
        y: 0,
        width: videoOriginalWidth,
        height: videoOriginalHeight,
        backgroundColor: 'transparent'
    });

    ScrollTrigger.create({
        trigger: gallerySection,
        start: 'top top',
        // once: true,
        fastScrollEnd: 5000,
        pin: true,
        pinSpacing: true,
        anticipatePin: 1,
    });
};

const galleryParallax = (gsap) => {
    const galleryContainer = document.querySelector('.home-hero__grid-gallery');
    const images = galleryContainer.querySelectorAll('.image');
    const startPosition = 0;

    const getRandom = (min, max) => {
        return Math.random(min, max) * min;
    }

    images.forEach((image) => {
        const scrollDistance = getRandom(-100, -150);
        const item = image.querySelector('img');

        ScrollTrigger.create({
            trigger: galleryContainer,
            start: 'top 70%',
            end: 'bottom top',
            delay: getRandom(10, 20),
            duration: getRandom(100, 1000),
            scrub: getRandom(10, 20),
            ease: 'power1.inOut',
            onUpdate: (self) => {
                if (self.direction === 1) {
                    gsap.to(item, {
                        y: self.direction * scrollDistance,
                        immediateRender: false
                    })
                } else {
                    gsap.to(item, {
                        y: startPosition,
                        immediateRender: false
                    })
                }
            }
        })
    });
};

const outroAnimation = (gsap) => {
    const outroContainer = document.querySelector('.home-hero__outro');

    if (!outroContainer) return;

    const logo = outroContainer.querySelector('.logo');
    const outro = outroContainer.querySelector('.headline');
    const icon = outroContainer.querySelector('.scroll-down');

    ScrollTrigger.create({
        trigger: outroContainer,
        // start: 'top center+=200',
        start: 'top bottom',
        end: 'bottom bottom',
        once: true,
        ease: 'none',
        snap: 0.5,
        // fastScrollEnd: 5000,
        scrub: 1,
        onEnter: () => {
            // gsap.to(window, {
            //     delay: 1,
            //     duration: 2,
            //     scrollTo: outroContainer
            // })

            gsap.to(logo, {
                delay: 1,
                duration: 2,
                y: 0,
                scale: 1,
                opacity: 1,
                ease: 'power1.out',
            })

            gsap.to(outro, {
                delay: 1.5,
                duration: 2,
                y: 0,
                opacity: 1,
                ease: 'power1.out',
            })

            gsap.to(icon, {
                delay: 4,
                duration: 2,
                y: 0,
                opacity: 1,
                ease: 'power1.out',
            })

        }
    })

};

const hideHeader = (gsap) => {
    const header = document.querySelector('#header');

    const tl = gsap.timeline({
        scrollTrigger: {
            trigger: document.body,
            start: 'top top',
            scrub: true,
            onUpdate: (self) => {
                if (self.direction === 1) {
                    gsap.to(header, {
                        y: '-100%',
                        duration: 0.1,
                        ease: 'none',
                    })
                }

                if (self.direction === -1) {
                    gsap.to(header, {
                        duration: 0.2,
                        y: '0%',
                        ease: 'sine.out',
                    })
                }
            }
        }
    })
};


// const serviceCards = () => {
//     const serviceSection = document.querySelector("#card-slider");

//     if (serviceSection) {
//         const cards = gsap.utils.toArray(".card-services__item");
//         const trigger = document.querySelector("#card-slider");
//         let maxWidth = 0;


//         const getMaxWidth = () => {
//             maxWidth = 0;
//             cards.forEach((card) => {
//                 maxWidth += card.offsetWidth;
//             });

//             return maxWidth;
//         };
//         getMaxWidth();

//         gsap.to(cards, {
//             x: () => `-${maxWidth - window.innerWidth}`,
//             ease: "none",
//             scrollTrigger: {
//                 trigger: trigger,
//                 pin: true,
//                 pinSpacing: true,
//                 scrub: 1,
//                 snap:
//                     window.innerWidth < 737
//                         ? {
//                             snapTo: 1 / (cards.length - 1),
//                             directional: false,
//                         }
//                         : false,
//                 end: () => `+=${maxWidth}`,
//             },
//         });

//         cards.forEach((card, i) => {
//             gsap.from(card, {
//                 scrollTrigger: {
//                     trigger: trigger,
//                     start: "top center",
//                     end: () => "+=" + (maxWidth + card.offsetWidth),
//                     toggleClass: {
//                         targets: card,
//                         className: "active-card",
//                     },
//                 },
//             });
//         });
//     }
// }

const serviceCards = (gsap) => {
    const container = document.querySelector('#card-slider');

    if (!container) return;

    const slides = container.querySelectorAll('.card-services__item');


    let sectionWidth = 0;

    for (let i = 0; i < slides.length; i++) {
        sectionWidth += parseInt(slides[i].offsetWidth, 10);
    }

    const section = document.querySelector('.card-services');
    const cards = gsap.utils.toArray('.card-services__item');

    gsap.to(cards, {
        x: () => `-${sectionWidth - window.innerWidth + 16}`,
        ease: "none",
        delay: 1,
        scrollTrigger: {
            trigger: container,
            pin: true,
            scrub: 1,
            // snap: 1 / (cards.length - 1),
            start: 'top top',
            end: () => '+=' + (sectionWidth + window.innerWidth),
        }
    });
}

const numberCount = (gsap) => {
    const triggerContainer = document.querySelectorAll('.project-counter');

    if (!triggerContainer.length) return;

    triggerContainer.forEach(container => {
        const numbers = container.querySelectorAll('.number');

        numbers.forEach(number => {
            const endValue = parseFloat(number.dataset.number);

            const observer = new IntersectionObserver(entries => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        gsap.to(number, {
                            duration: 3,
                            textContent: endValue,
                            snap: { textContent: 1 },
                            ease: "power1.inOut"
                        });
                        observer.unobserve(number);
                    }
                });
            }, { rootMargin: '0px 0px -5% 0px' });

            observer.observe(number);
        });
    })
}

const compareImages = (gsap) => {
    const container = document.querySelectorAll('.media-comparison-slider');

    if (!container.length) return;

    container.forEach((el) => {
        const after = el.querySelector('.after');

        let tl = gsap.timeline({
            scrollTrigger: {
                trigger: el,
                start: "center center",
                end: () => "+=" + el.offsetWidth,
                scrub: 1,
                pin: true,
                pinType: "fixed",
                anticipatePin: 1,
            },
            defaults: { ease: "none" },
        });
        tl.fromTo(after, { xPercent: 100, x: 0 }, { xPercent: 0 }).fromTo(after.querySelector('img'), { xPercent: -100, x: 0 }, { xPercent: 0 }, 0);
    });
};

const stickerAnimation = (gsap) => {
    const spinningElements = document.querySelectorAll('.sticker');

    // console.log(spinningElements)

    spinningElements.forEach(element => {
        gsap.timeline({
            scrollTrigger: {
                trigger: element,
                start: "top bottom", // Animation starts when element enters viewport
                end: "bottom top", // Animation ends when element leaves viewport
                scrub: true, // Smoothly ties animation progress to scroll position
                toggleActions: "play none none reverse"
            }
        })
        .to(element, {
            rotation: 360, // Full circle rotation
            duration: 1,
            ease: "none" // Linear animation for smooth rotation
        });
    });
}


document.addEventListener('DOMContentLoaded', () => {
    if (isBackend) return;

    videoAnimation(gsap);
    outroAnimation(gsap);
    serviceCards(gsap);
    numberCount(gsap);
    compareImages(gsap);
    stickerAnimation(gsap);
});







