// Vendor & Custom CSS
import 'swiper/css';
import './styles/index.scss';

// Bootstrap JS
import 'bootstrap/js/dist/offcanvas';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/dropdown';

// Custom JS
import './js/swiper';
import './js/animations';
import './js/video';
import './js/form';

